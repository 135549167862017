/*
 * @Author: DESKTOP-QME64HA\chen wangchen_0429@163.com
 * @Date: 2021-11-24 21:42:08
 * @LastEditors: DESKTOP-QME64HA\chen wangchen_0429@163.com
 * @LastEditTime: 2022-07-15 10:33:54
 * @FilePath: \ct-web\src\env.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
let baseURL;
let portalV1URL;
let portalV2URL;
let oauth2ClientID;

// 后端 api URL
const backendLocal = 'http://localhost:8080/api';
const backendTest = 'https://test.shuishan.net.cn/api';
const backendProd = 'https://www.shuishan.net.cn/api';
// portal v1 URL
const v1Local = 'http://localhost:9557';
const v1Test = 'https://test.shuishan.net.cn';
const v1Prod = 'https://www.shuishan.net.cn'
// portal v2 URL
const v2Local = 'http://localhost:9547';
const v2Test = 'https://test.v2.shuishan.net.cn';
const v2Prod = 'https://v2.shuishan.net.cn'

switch (process.env.NODE_ENV) {
  case 'dev-local':
    // 外网开发环境
    baseURL = backendLocal;
    portalV1URL = v1Local;
    portalV2URL = v2Local;
    oauth2ClientID = process.env.VUE_APP_OAUTH2_CLIENT_ID;
    break;
  case 'dev-test':
    // 外网开发环境
    baseURL = backendTest;
    portalV1URL = v1Local;
    portalV2URL = v2Local;
    oauth2ClientID = process.env.VUE_APP_OAUTH2_CLIENT_ID;
    break;
  case 'dev-prod':
    baseURL = backendProd;
    portalV1URL = v1Local;
    portalV2URL = v2Local;
    oauth2ClientID = process.env.VUE_APP_OAUTH2_CLIENT_ID;
    break;
  case 'test':
    baseURL = backendTest;
    portalV1URL = v1Test;
    portalV2URL = v2Test;
    oauth2ClientID = process.env.VUE_APP_OAUTH2_CLIENT_ID;
    break;
  case 'production':
    baseURL = backendProd;
    portalV1URL = v1Prod;
    portalV2URL = v2Prod;
    oauth2ClientID = process.env.VUE_APP_OAUTH2_CLIENT_ID;
    break;
  default:
    baseURL = backendProd;
    portalV1URL = v1Prod;
    portalV2URL = v2Prod;
    oauth2ClientID = process.env.VUE_APP_OAUTH2_CLIENT_ID;
    break;
}

export { baseURL, portalV1URL, portalV2URL, oauth2ClientID };
