/**
 * Vuex-actions
 */
 import { removeToken } from '@/util/auth'
export default {
  setUserName(context,username){
    context.commit('setUserName', username);
  },
  setAvatar(context, headPortrait) {
    context.commit('setAvatar', headPortrait);
  },
  setUsername(context, studentName) {
    context.commit('setUsername', studentName);
  },
  setUserId(context, id) {
    context.commit('setUserId', id);
  },
  setUserNumber(context, userNumber) {
    context.commit('setUserNumber', userNumber);
  },
  FedLogOut({ commit }) {
    return new Promise(resolve => {
      commit('setAvatar', '')
      commit('setUsername', '')
      commit('setUserName', '')
      commit('setUserId', '')
      commit('setUserNumber', '')
      commit('setSelectedCompany', '')
      commit('setCompanies', [])
      removeToken()
      resolve()
    })
  },
  setCourseId(context, courseId) {
    context.commit('setCourseId', courseId)
  },
  setSelectedCompany ({ commit }, selectedCompany) {
    commit('setSelectedCompany', selectedCompany)
  },
  setAuthorizationFailed (context, authorizationFailed) {
    context.commit('setAuthorizationFailed', authorizationFailed);
  },
  setCompanies({ commit }, companies) {
    commit('setCompanies', companies);
  }
}
