/**
 * Vuex-mutations
 */
export default {
  setUserName(state, username) {
    state.username = username;
  },
  setAvatar(state, headPortrait) {
    state.headPortrait = headPortrait;
  },
  setUsername(state, studentName) {
    state.studentName = studentName;
  },
  setUserId(state, userId) {
    state.userId = userId;
  },
  setUserNumber(state, userNumber) {
    state.userNumber = userNumber;
  },
  setCourseId(state, courseId) {
    state.courseId = courseId;
  },
  setSelectedCompany(state, selectedCompany) {
    state.selectedCompany = selectedCompany;
  },
  setAuthorizationFailed(state, authorizationFailed) {
    state.authorizationFailed = authorizationFailed;
  },
  setCompanies(state, companies) {
    state.companies = companies;
  }
}
