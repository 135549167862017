/*
 * @Description: vuex-state
 * @Date: 2021-11-04 11:02:45
 * @LastEditTime: 2024-12-09 16:58:56
 */

export default {
  headPortrait:"",
  studentName:"",
  username:"",
  userId: "",
  courseId: "", // 我的课程->课程看板传的courseId
  userNumber: "",
  selectedCompany: "",
  authorizationFailed: false,  // 是否认证失败，401 Error 时触发
  companies: [] // 用于存储下拉列表的所有租户
};
