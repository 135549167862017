export default {
  getHeadPortrait(state) {
    return state.headPortrait;
  },
  getSelectedCompany(state) {
    return state.selectedCompany;
  },
  isAuthorizationFailed(state) {
    return state.authorizationFailed;
  }
};
